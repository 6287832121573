import { Injectable, signal } from '@angular/core';
import {
  AdditionalLicenseTransactionFeature,
  AreaType,
  BookingProvider,
  CountryRegion,
  CreatedFrom,
  CreatedFromType,
  DataProvider,
  DepartmentType,
  DeviceHistoryType,
  DeviceState,
  DeviceType,
  FacilityProvider,
  FacilityType,
  JobRateUnit,
  LicenseFeature,
  LicensePackage,
  LicenseTransaction,
  LoadingState,
  MasterActivity,
  Organization,
  OrganizationType,
  RolePackage,
  TimeType,
  TimeZone,
  TokenUser,
} from '@shared/types';

export interface BaseSettings {
  areaTypes: AreaType[];
  bookingProviders: BookingProvider[];
  countryRegions: CountryRegion[];
  createdFromTypes: CreatedFromType[];
  createdFroms: CreatedFrom[];
  departmentTypes: DepartmentType[];
  deviceHistoryTypes: DeviceHistoryType[];
  deviceTypes: DeviceType[];
  deviceStates: DeviceState[];
  facilityTypes: FacilityType[];
  hallmonitorActivityTypes: MasterActivity[];
  licensePackages: LicensePackage[];
  organizationTypes: OrganizationType[];
  rolePackages: RolePackage[];
  timeTypes: TimeType[];
  timeZones: TimeZone[];
  facilityProviders: FacilityProvider[];
  licenseFeatures: LicenseFeature[];
  activeLicenses: LicenseTransaction[];
  additionalLicenses: AdditionalLicenseTransactionFeature[];
  jobRateUnits: JobRateUnit[];
  dataProviders: DataProvider[];
}

@Injectable({ providedIn: 'root' })
export class SharedStore {
  private _base: BaseSettings = {} as BaseSettings;
  private _user: TokenUser = {} as TokenUser;
  private _authorizedRoutes: string[] = [];
  private _organizations = signal<{ list: Organization[]; loading: LoadingState }>({
    list: [],
    loading: LoadingState.INIT,
  });
  public organizationId = signal<number>(0);

  base = { get: () => this._base, set: (settings: BaseSettings) => (this._base = settings) };

  authorizedRoutes = {
    get: () => this._authorizedRoutes,
    set: (routes: string[]) => (this._authorizedRoutes = routes),
  };

  user = {
    get: () => this._user,
    set: (newUser: TokenUser) => {
      this._user = {
        ...newUser,
        isHallMonitorUser: newUser.authorized_roles.toLowerCase() === 'system administrator' || newUser.authorized_roles.toLowerCase() === 'hallmonitor user',
        nameWithRole: `${newUser.name} (${newUser.authorized_roles})`,
        is_system_admin: newUser.authorized_roles.toLowerCase() === 'system administrator'
      };
      this.authorizedRoutes.set(newUser.authorized_routes?.split(','));
    },
    remove: () => (this._user = {} as TokenUser),
  };

  rolePackages = {
    get: () => this._base.rolePackages,
    set: (rolePackages: []) => (this._base.rolePackages = rolePackages),
  };

  dataProviders = {
    get: () => this._base.dataProviders,
    set: (dataProviders: []) => (this._base.dataProviders = dataProviders),
  };

  hallmonitorActivityTypes = {
    get: () => this._base.hallmonitorActivityTypes,
    set: (activityTypes: MasterActivity[]) => (this._base.hallmonitorActivityTypes = activityTypes),
  };

  organizations = {
    get: () => this._organizations().list,
    set: (list: Organization[]) => this._organizations.set({ list, loading: LoadingState.LOADED }),
  };
}
